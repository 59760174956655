// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import message from 'antd/lib/message';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

import { CombinedState } from 'reducers/interfaces';
import { rememberObject, updateAnnotationsAsync } from 'actions/annotation-actions';
import LabelItemContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/label-item';
import GlobalHotKeys from 'utils/mousetrap-react';
import { useParams } from 'react-router';
import { imageQualitySet  } from 'components/annotation-page/standard-workspace/objects-side-bar/image-quality-data';
import { useSelectedContext } from 'components/annotation-page/job-preview/Provider';

function ImageQualityList(): JSX.Element {
    const context = useSelectedContext();
    const dispatch = useDispatch();
    const activatedStateID = useSelector((state: CombinedState) => state.annotation.annotations.activatedStateID);
    const states = useSelector((state: CombinedState) => state.annotation.annotations.states);
    const keyMap = useSelector((state: CombinedState) => state.shortcuts.keyMap);
    const { jid }:any = useParams();
    const [currentValue, setCurrentValue] = useState('None');



    useEffect(() => {
        axios.get(`/api/jobs/${jid}/quality`).then((response) => setCurrentValue(response.data.image_quality));
    }, []);


    const setImageQuality = (event) => {

        setCurrentValue(event.target.value)
        axios.patch(`/api/jobs/${jid}/quality`, { image_quality: event.target.value });
    };

    return (
        <div className='cvat-objects-sidebar-labels-list'>

            <select className='cvat-objects-sidebar-labels-list-dropdown'
                    name='image-quality'
                    id='image-quality'
                    value={currentValue}
                    onChange={e => setImageQuality(e)}
            >
                {
                // eslint-disable-next-line max-len
                    imageQualitySet.map((label: any) => <option value={label}>{label}</option>)
                }
            </select>
        </div>
    );
}

export default React.memo(ImageQualityList);
