// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, {
    ChangeEvent, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import message from 'antd/lib/message';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

import { CombinedState } from 'reducers/interfaces';
import {
    setImageType,
} from 'actions/annotation-actions';
import { useParams } from 'react-router';
import { ImageTypeSet } from 'components/annotation-page/standard-workspace/objects-side-bar/image-type-data';

function ImageTypeList(): JSX.Element {
    const { jid }:any = useParams();
    const dispatch = useDispatch();
    const currentImageType = useSelector((state: CombinedState) => state.annotation.annotations.imageType);

    useEffect(() => {
        axios.get(`/api/jobs/${jid}/image-type`).then((response) => {
            dispatch(setImageType(response.data.image_type));
        });
    }, []);

    const updateImageType = (event: ChangeEvent<HTMLSelectElement>): void => {
        const previousImageType = currentImageType;
        dispatch(setImageType(event.target.value));

        axios.patch(`/api/jobs/${jid}/image-type`, { image_type: event.target.value }).catch(() => {
            dispatch(setImageType(previousImageType));
            message.error('Could not update image type');
        });
    };

    return (
        <div className='cvat-objects-sidebar-labels-list'>
            <select
                className='cvat-objects-sidebar-labels-list-dropdown'
                name='image-type'
                id='image-type'
                value={currentImageType}
                onChange={(e) => updateImageType(e)}
            >
                {
                    ImageTypeSet.map((label: any) => (
                        <option key={label} value={label}>
                            {label}
                        </option>
                    ))
                }
            </select>
        </div>
    );
}

export default React.memo(ImageTypeList);
