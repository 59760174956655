// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React from 'react';
import { useHistory } from 'react-router';
import Empty from 'antd/lib/empty';
import './styles.scss';

interface Props {
    job: any;
    preview: string;
    className: any;
}

function JobCardPreview(props: Props): JSX.Element {
    const {
        job, preview, className,
    } = props;

    const history = useHistory();
    // eslint-disable-next-line consistent-return
    const onClick = (event: React.MouseEvent): void => {
        const url = `/tasks/${job.taskId}/jobs/${job.id}`;
        if (event.ctrlKey) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(url, '_blank', 'noopener noreferrer');
        } else {
            history.push(url);
            window.location.reload();
        }
    };

    // const scroll = ():any => {
    //     window.scrollTo({
    //         top: scrollIntoView?.current?.offsetTop,
    //         behavior: 'smooth',
    //     });
    // };

    return (
        <>
            {preview ? (
                <div>
                    <img
                        width='280px'
                        height='210px'
                        className={className}
                        src={preview}
                        alt='Preview'
                        onClick={onClick}
                        aria-hidden
                    />
                </div>

            ) : (
                <div className='cvat-jobs-page-job-item-card-preview' onClick={onClick} aria-hidden>
                    <Empty description='Preview not found' />
                </div>
            )}
        </>
    );
}

export default React.memo(JobCardPreview);
