// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';

import { Row, Col } from 'antd/lib/grid';
import { LinkOutlined } from '@ant-design/icons';
import Slider from 'antd/lib/slider';
import InputNumber from 'antd/lib/input-number';
import Input from 'antd/lib/input';
import Text from 'antd/lib/typography/Text';

import CVATTooltip from 'components/common/cvat-tooltip';
import { clamp } from 'utils/math';

interface Props {
    startFrame: number;
    stopFrame: number;
    frameNumber: number;
    frameFilename: string;
    focusFrameInputShortcut: string;
    inputFrameRef: React.RefObject<Input>;
    onSliderChange(value: number): void;
    onInputChange(value: number): void;
    onURLIconClick(): void;
}

function PlayerNavigation(props: Props): JSX.Element {
    const {
        startFrame,
        stopFrame,
        frameNumber,
        frameFilename,
        focusFrameInputShortcut,
        inputFrameRef,
        onSliderChange,
        onInputChange,
        onURLIconClick,
    } = props;

    const [frameInputValue, setFrameInputValue] = useState<number>(frameNumber);

    useEffect(() => {
        if (frameNumber !== frameInputValue) {
            setFrameInputValue(frameNumber);
        }
    }, [frameNumber]);

    console.log('start/stop', startFrame, stopFrame);

    return (
        <>
            {startFrame !== stopFrame && (
                <>
                    <Col className='cvat-player-controls'>
                        <Row align='bottom'>
                            <Col>
                                <Slider
                                    className='cvat-player-slider'
                                    min={startFrame}
                                    max={stopFrame}
                                    value={frameNumber || 0}
                                    onChange={onSliderChange}
                                />
                            </Col>
                        </Row>
                        <Row justify='center'>
                            <Col className='cvat-player-filename-wrapper'>
                                <CVATTooltip title={frameFilename}>
                                    <Text type='secondary'>{frameFilename}</Text>
                                </CVATTooltip>
                            </Col>
                            <Col offset={1}>
                                <CVATTooltip title='Create frame URL'>
                                    <LinkOutlined className='cvat-player-frame-url-icon' onClick={onURLIconClick} />
                                </CVATTooltip>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <CVATTooltip title={`Press ${focusFrameInputShortcut} to focus here`}>
                            <InputNumber
                                ref={inputFrameRef}
                                className='cvat-player-frame-selector'
                                type='number'
                                value={frameInputValue}
                                onChange={(value: number | undefined | string | null) => {
                                    if (typeof value !== 'undefined' && value !== null) {
                                        setFrameInputValue(Math.floor(clamp(+value, startFrame, stopFrame)));
                                    }
                                }}
                                onBlur={() => {
                                    onInputChange(frameInputValue);
                                }}
                                onPressEnter={() => {
                                    onInputChange(frameInputValue);
                                }}
                            />
                        </CVATTooltip>
                    </Col>
                </>
            ) }
        </>
    );
}

export default React.memo(PlayerNavigation);
