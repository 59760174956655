/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable header/header */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import './styles.scss';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Spin from 'antd/lib/spin';
import { Col } from 'antd/lib/grid';
import { Tooltip } from 'antd';
// import Empty from 'antd/lib/empty';
// import Text from 'antd/lib/typography/Text';
import { updateHistoryFromQuery } from 'components/resource-sorting-filtering';
import { CombinedState, Indexable } from 'reducers/interfaces';
import { getJobsAsync } from 'actions/jobs-actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import JobCardPreview from './job-card-preview';
import { useSelectedContext } from './Provider';
import { saveToLocalStorage, getFromLocalStorage } from 'components/task-page/localStorageUtils'

function JobsPageComponent(): JSX.Element {
    const context = useSelectedContext();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isMounted, setIsMounted] = useState(false);
    const query = useSelector((state: CombinedState) => state.jobs.query);
    const fetching = useSelector((state: CombinedState) => state.jobs.fetching);
    // const count = useSelector((state: CombinedState) => state.jobs.count);
    const jobs = useSelector((state: CombinedState) => state.jobs.current);
    const previews = useSelector((state: CombinedState) => state.jobs.previews);
    const jobsReversed = [...jobs];
    const previewsReversed = [...previews];
    const { jid }:any = useParams();
    const selectedCardValue = context.selectedCard;

    jobsReversed.reverse();
    previewsReversed.reverse();

    const dimensions = {
        md: 22,
        lg: 18,
        xl: 16,
        xxl: 16,
    };
    const queryParams = new URLSearchParams(history.location.search);
    const location = useLocation();
    const updatedQuery = { ...query };
    const imageType = getFromLocalStorage('selectedImageType');
    const viewType = getFromLocalStorage('selectedViewType');

    let scrollToView: HTMLDivElement;
    for (const key of Object.keys(updatedQuery)) {
        (updatedQuery as Indexable)[key] = queryParams.get(key) || null;
        if (key === 'page') {
            updatedQuery.page = updatedQuery.page ? +updatedQuery.page : 1;
        }
    }

    const getTaskId = (urlPath: any): any => {
        const str = urlPath.substring(
            urlPath.indexOf('/') + 7,
            urlPath.lastIndexOf('/jobs'),
        );
        return str;
    };

    const highlighted = (jobId: any): any => {
        if (jobId === +jid) {
            return 'highlighted-preview';
        }
        return 'preview';
    };
    useEffect(() => {
        if (jobsReversed.length > 0){
            let appear = false;
            const selectedJobId = getFromLocalStorage('selectedJobId')
            for (const element of jobsReversed){
                if (element.id === selectedJobId){
                    appear = true;
                }
            }
            if (!appear){
                const firstJob = jobsReversed[0];
                saveToLocalStorage('selectedJobId', firstJob.id)
                history.push(`/tasks/${firstJob.taskId}/jobs/${firstJob.id}`);
                window.location.reload();
            }
        }

    }, [selectedCardValue, jobsReversed, history, context]);
    useEffect(() => {

        const filterObj = {
            and: [
                { "==": [{ var: "task_id" }, getTaskId(location.pathname)] },
            ],
        };

        if (imageType !== null && imageType !== undefined && imageType !== 'None') {
            filterObj.and.push({ "==": [{ var: "image_type" }, imageType] });
        }

        if (viewType !== null && viewType !== undefined && viewType !== 'all') {
            filterObj.and.push({ "==": [{ var: "labelset" }, viewType] });
        }

        dispatch(
            getJobsAsync({
                ...query,
                filter: JSON.stringify(filterObj),
                page: 1,
            })
        );

        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            history.replace({
                search: updateHistoryFromQuery(query),
            });
        }
    }, [query]);

    const content = (
        <>
            <Col className='cvat-jobs-page-list-preview' {...dimensions}>
                {jobsReversed.map((job: any, idx: number): JSX.Element => (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions

                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div
                        ref={(node) => {
                            if (job.id === +jid) {
                                if (node) {
                                    scrollToView = node;
                                    scrollToView.scrollIntoView({ behavior: 'smooth' });
                                }
                            }
                        }}
                        onClick={() => {
                            context.setSelectedCard(idx);
                            if (job.id > 0){
                                saveToLocalStorage('selectedJobId', job.id)
                            }
                        // return history.push(`/tasks/${job.taskId}/jobs/${job.id}`);
                        }}
                    >
                        <Tooltip title={`${job.dicom_name}`}>
                            <div>
                                <JobCardPreview
                                    preview={previewsReversed[idx]}
                                    job={job}
                                    key={job.id}
                                    className={highlighted(job.id)}
                                />
                            </div>
                        </Tooltip>
                    </div>
                ))}
            </Col>
        </>
    );
    // ) : <Empty description={<Text>No results matched your search...</Text>} />;

    return (
        <div className='cvat-jobs-preview'>
            { fetching ? (
                <Spin size='large' className='cvat-spinner-preview' />
            ) : content }
            {/* <FeedbackComponent /> */}
        </div>
    );
}

export default React.memo(JobsPageComponent);
