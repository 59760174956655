import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal';
import Radio from 'antd/lib/radio';
import Checkbox from 'antd/lib/checkbox';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import Notification from 'antd/lib/notification';

const mapStateToProps = (state: CombinedState) => {
  return {
    frameNumber: state.annotation.player.frame.number,
    jobID: state.annotation.job.requestedId,
    stopFrame: state.annotation.job.instance.stopFrame
  };
};

const AIModelsModal = ({ visible, onClose, onConfirm, frameNumber, jobID, stopFrame, context}) => {
  const [selectedModel, setSelectedModel] = useState('current');
  const [checkboxData, setCheckboxData] = useState([]);
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [startFrame, setStartFrame] = useState('');
  const [endFrame, setEndFrame] = useState('');
  const [customFrames, setCustomFrames] = useState([0, 0]);
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);

  useEffect(() => {
    const fetchCheckboxData = async () => {
      try {
        const response = await fetch(`/api/models/viewtype?type=${selectedModel}&viewtype=${context.view}`);
        const data = await response.json();

        const initialData = data.map(item => ({ id: item.name, name: item.name, checked: false }));
        setCheckboxData(initialData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCheckboxData();
  }, [selectedModel, context.view]);

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
    setSelectedCheckboxIds([]);
  };

  const handleCheckboxChange = (id) => {
    setCheckboxData(prevData => {
      const newData = prevData.map(item => {
        if (item.id === id) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
      return newData;
    });

    setSelectedCheckboxIds(prevIds => {
      if (prevIds.includes(id)) {
        return prevIds.filter(selectedId => selectedId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const handleStartFrameChange = (e) => {
    const start = parseInt(e.target.value);
    setStartFrame(start);
    setCustomFrames(prevFrames => [start, prevFrames[1]]);

    if (endError || (endFrame !== '' && (start < 0 || start >= customFrames[1]))) {
      setStartError(true);
    } else {
      setStartError(false);
    }
  };

  const handleEndFrameChange = (e) => {
    const end = parseInt(e.target.value);
    setEndFrame(end);
    setCustomFrames(prevFrames => [prevFrames[0], end]);

    if (end < 0 || end > stopFrame) {
      setEndError(true);
    } else {
      setEndError(false);
    }

    if (endError || (startFrame !== '' && (startFrame < 0 || startFrame >= end))) {
      setStartError(true);
    } else {
      setStartError(false);
    }
  };

  const handleButtonClick = () => {
    let framesValue = '';
    if (selectedModel === 'current') {
      framesValue = frameNumber.toString();
    } else if (selectedModel === 'custom') {
      framesValue = `${customFrames[0]},${customFrames[1]}`;
    } else {
      framesValue = `${startFrame}, ${endFrame}`;
    }

    const queryParams = {
      job_id: jobID,
      type: selectedModel,
      frames: framesValue,
      models: selectedCheckboxIds.join(','),
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const apiUrl = `/api/models/apply?${queryString}`;

    fetch(apiUrl)
      .then(data => {
        onConfirm();
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      Notification.info({
        message: 'Work in progres',
        description:
            'Please wait, page will be reloaded.',
        duration: 0,
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="AI Models"
      footer={null}
    >
      <div className="AIModelsModal-container">
        <Radio.Group onChange={handleModelChange} value={selectedModel}>
          <div className="AIModelsModal-radioContainer">
            <Radio value="current">Current frame</Radio>
            {selectedModel === 'current' && (
              <OptionsList
                checkboxData={checkboxData}
                selectedCheckboxIds={selectedCheckboxIds}
                onChange={handleCheckboxChange}
              />
            )}
          </div>
          <div className="AIModelsModal-radioContainer">
            <Radio value="whole_dicom">Whole dicom</Radio>
            {selectedModel === 'whole_dicom' && (
              <OptionsList
                checkboxData={checkboxData}
                selectedCheckboxIds={selectedCheckboxIds}
                onChange={handleCheckboxChange}
              />
            )}
          </div>
          <div className="AIModelsModal-radioContainer">
            <Radio value="custom">Custom</Radio>
          </div>
        </Radio.Group>

        {selectedModel === 'custom' && (
          <div className="AIModelsModal-customInputContainer">
            <div className="inputRow">
              <label className="startFrameLabel">Begin:</label>
              <input
                type="number"
                placeholder="Start Frame"
                value={customFrames[0]}
                onChange={handleStartFrameChange}
              />
              {startError && (
                <small className="errorText">Start frame should be greater than or equal to 0 and less than end frame.</small>
              )}
            </div>
            <div className="inputRow">
              <label className="endFrameLabel">End:</label>
              <input
                type="number"
                placeholder="End Frame"
                value={customFrames[1]}
                onChange={handleEndFrameChange}
              />
              {endError && (
                <small className="errorText">End frame should be greater than 0 and less than or equal to {stopFrame}.</small>
              )}
            </div>
            <OptionsList
              checkboxData={checkboxData}
              selectedCheckboxIds={selectedCheckboxIds}
              onChange={handleCheckboxChange}
            />
          </div>
        )}
      </div>
      <div>
        <button className="confirmButton" onClick={handleButtonClick}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

const OptionsList = ({ checkboxData, selectedCheckboxIds, onChange }) => {
  return (
    <div className="optionsList-container">
      <span className="optionsList-text">Options:</span>
      <ul className="optionsList-list">
        {checkboxData.map((item) => {
          return (
            <li key={item.id} className="optionsList-listItem">
              <Checkbox
                checked={item.checked}
                onChange={() => onChange(item.id)}
              >
                {item.name}
              </Checkbox>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default connect(mapStateToProps)(AIModelsModal);
