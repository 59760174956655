/* eslint-disable react/destructuring-assignment */
/* eslint-disable header/header */
import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BehaviorSubject } from 'rxjs';

const selectedCard$ = new BehaviorSubject<number>(0);
const selectedJobId$ = new BehaviorSubject<number>(0);
const view$ = new BehaviorSubject<string>('all');
const labelsetCreatedBy$ = new BehaviorSubject<string>('U');
const selectedImageType$ = new BehaviorSubject('None');
const selectedViewType$ = new BehaviorSubject('all');
export const SelectedContext = React.createContext<ISelectedContext>({} as ISelectedContext);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

interface ISelectedContext {
    view: string;
    setView: (value: string) => void;
    selectedCard: number;
    setSelectedCard: (value: number) => void;
    labelsetCreatedBy: string;
    setLabelsetCreatedBy: (value: string) => void;
    selectedImageType: string,
    setSelectedImageType: (value: string) => void;
    selectedViewType: string,
    setSelectedViewType: (value: string) => void;
    selectedJobId: number | null;
    setSelectedJobId: (value: number | null) => void;

}

export const useSelectedContext = () => {
    const context = useContext(SelectedContext);
    if (!context) {
        throw new Error('error');
    }
    return context;
};


const Provider = (props: any) => {
    const [selectedCard, setSelectedCard] = useState(() => selectedCard$.value);
    const [view, setView] = useState(() => view$.value);
    const [labelsetCreatedBy, setLabelsetCreatedBy] = useState(() => view$.value);
    const [selectedImageType, setSelectedImageType] = useState(() => selectedImageType$.value);
    const [selectedViewType, setSelectedViewType] = useState(() => selectedViewType$.value);
    const [selectedJobId, setSelectedJobId] = useState<number | null>(null);

    useEffect(() => {
        selectedCard$.subscribe(setSelectedCard);
        view$.subscribe(setView);
        labelsetCreatedBy$.subscribe(setLabelsetCreatedBy);
        selectedJobId$.subscribe(setSelectedJobId);
    }, []);

    return (
        <SelectedContext.Provider
            value={{
                view,
                setView: (v) => view$.next(v),
                selectedCard,
                setSelectedCard: (idx) => selectedCard$.next(idx),
                labelsetCreatedBy,
                setLabelsetCreatedBy: (l) => labelsetCreatedBy$.next(l),
                selectedImageType,
                setSelectedImageType: (s) => selectedImageType$.next(s),
                selectedViewType,
                setSelectedViewType: (w) => selectedViewType$.next(w),
                selectedJobId,
                setSelectedJobId: (id) => selectedJobId$.next(id),
            }}
        >
            {props.children}
        </SelectedContext.Provider>
    );
};

export default Provider;
