// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import message from 'antd/lib/message';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

import { CombinedState } from 'reducers/interfaces';
import { rememberObject, updateAnnotationsAsync } from 'actions/annotation-actions';
import LabelItemContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/label-item';
import GlobalHotKeys from 'utils/mousetrap-react';
import { useParams } from 'react-router';
import { labelSets, labelFilterObject } from 'components/annotation-page/standard-workspace/objects-side-bar/labelset-data';
import { UbmLabelSet} from 'components/annotation-page/standard-workspace/objects-side-bar/ubm-labels-data';

import { useSelectedContext } from 'components/annotation-page/job-preview/Provider';

function LabelsListComponent(props): JSX.Element {
    const context = useSelectedContext();
    const dispatch = useDispatch();
    const [TEE, setTEE] = useState(true);
    const [UBM, setUBM] = useState(true);
    const labels = useSelector((state: CombinedState) => state.annotation.job.labels);
    const activatedStateID = useSelector((state: CombinedState) => state.annotation.annotations.activatedStateID);
    const states = useSelector((state: CombinedState) => state.annotation.annotations.states);
    const keyMap = useSelector((state: CombinedState) => state.shortcuts.keyMap);
    const { jid }:any = useParams();
    const filterLabels = (): string[] => Object.entries(labelFilterObject).flatMap(([key, values]) => {
        if (key === context.view) {
            return values;
        }
        return [];
    });

    const selectedLabels = labels.filter((x) => filterLabels().includes(x.name));
    const filteredLabels = selectedLabels.length === 0 ? labels : selectedLabels;


    useEffect(() => {
        setTEE(selectedLabels.length > 0);
        // eslint-disable-next-line react/prop-types
        props.isTee(TEE);
    }, [selectedLabels]);

    const labelIDs = filteredLabels.map((label: any): number => label.id);

    const [keyToLabelMapping, setKeyToLabelMapping] = useState<Record<string, number>>(
        Object.fromEntries(labelIDs.slice(0, 10).map((labelID: number, idx: number) => [(idx + 1) % 10, labelID])),
    );
    useEffect(() => {
        axios.get(`/api/jobs/${jid}/labelsets`)
            .then((response) => {
                context.setView(response.data.labelset);
                context.setLabelsetCreatedBy(response.data.labelset_created_by);
            });
    }, []);

    const setLabels = () => {
      const select = document.getElementById('labelsets') as HTMLSelectElement;
      const option = select.options[select.selectedIndex] as HTMLOptionElement;

      axios.patch(`/api/jobs/${jid}/labelsets`, { labelset: option.value })
        .then(() => {
          axios.get(`/api/jobs/${jid}/labelsets`)
            .then((response) => {
              context.setLabelsetCreatedBy(response.data.labelset_created_by);
              context.setView(option.value);

            })
            .catch((error) => {
              console.error('Error:', error);
            });
        })
        .catch((error) => {
          console.error('Error:', error);
        });

    };

    const updateLabelShortcutKey = useCallback(
        (key: string, labelID: number) => {
            // unassign any keys assigned to the current labels
            const keyToLabelMappingCopy = { ...keyToLabelMapping };
            for (const shortKey of Object.keys(keyToLabelMappingCopy)) {
                if (keyToLabelMappingCopy[shortKey] === labelID) {
                    delete keyToLabelMappingCopy[shortKey];
                }
            }

            if (key === '—') {
                setKeyToLabelMapping(keyToLabelMappingCopy);
                return;
            }

            // check if this key is assigned to another label
            if (key in keyToLabelMappingCopy) {
                // try to find a new key for the other label
                for (let i = 0; i < 10; i++) {
                    const adjustedI = (i + 1) % 10;
                    if (!(adjustedI in keyToLabelMappingCopy)) {
                        keyToLabelMappingCopy[adjustedI] = keyToLabelMappingCopy[key];
                        break;
                    }
                }
                // delete assigning to the other label
                delete keyToLabelMappingCopy[key];
            }

            // assigning to the current label
            keyToLabelMappingCopy[key] = labelID;
            setKeyToLabelMapping(keyToLabelMappingCopy);
        },
        [keyToLabelMapping],
    );

    const subKeyMap = {
        SWITCH_LABEL: keyMap.SWITCH_LABEL,
    };

    const handlers = {
        SWITCH_LABEL: (event: KeyboardEvent | undefined, shortcut: string) => {
            if (event) event.preventDefault();
            const labelID = keyToLabelMapping[shortcut.split('+')[1].trim()];
            const label = labels.filter((_label: any) => _label.id === labelID)[0];
            if (Number.isInteger(labelID) && label) {
                if (Number.isInteger(activatedStateID)) {
                    const activatedState = states.filter((state: any) => state.clientID === activatedStateID)[0];
                    if (activatedState) {
                        activatedState.label = label;
                        dispatch(updateAnnotationsAsync([activatedState]));
                    }
                } else {
                    dispatch(rememberObject({ activeLabelID: labelID }));
                    message.destroy();
                    message.success(`Default label was changed to "${label.name}"`);
                }
            }
        },
    };

    return (
        <div className='cvat-objects-sidebar-labels-list'>
            { TEE && (
                <select className='cvat-objects-sidebar-labels-list-dropdown' name='labelsets' id='labelsets' value={context.view} onChange={() => setLabels()}>
                    {
                    // eslint-disable-next-line max-len
                        labelSets.map((label: any) => <option value={label}>{label}</option>)
                    }
                </select>
            )}
            {TEE}
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            {labelIDs.map(
                (labelID: number): JSX.Element => (
                    <LabelItemContainer
                        key={labelID}
                        labelID={labelID}
                        keyToLabelMapping={keyToLabelMapping}
                        updateLabelShortcutKey={updateLabelShortcutKey}
                    />
                ),
            )}
        </div>
    );
}

export default React.memo(LabelsListComponent);
