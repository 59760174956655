import React, { useState, useEffect, useContext } from 'react';
import Modal from 'antd/lib/modal';
import Select from 'antd/lib/select';
import { connect } from 'react-redux';
import Notification from 'antd/lib/notification';
import { ImageTypeSet } from 'components/annotation-page/standard-workspace/objects-side-bar/image-type-data';
import { labelSets } from 'components/annotation-page/standard-workspace/objects-side-bar/labelset-data';
const { Option } = Select;
import { useSelectedContext, SelectedContext } from 'components/annotation-page/job-preview/Provider';


export const saveToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Error saving to local storage:', error);
  }
};

export const getFromLocalStorage = (key) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  } catch (error) {
    console.error('Error getting from local storage:', error);
    return null;
  }
};
