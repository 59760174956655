// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { Dispatch, TransitionEvent, useState } from 'react';
import { AnyAction } from 'redux';
import { connect, useSelector } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Tabs from 'antd/lib/tabs';
import Layout from 'antd/lib/layout';

import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { CombinedState, DimensionType } from 'reducers/interfaces';
import LabelsList from 'components/annotation-page/standard-workspace/objects-side-bar/labels-list';
import RightViewTypeList from 'components/annotation-page/standard-workspace/objects-side-bar/right-view-type-list';
import PathologyTypeList from 'components/annotation-page/standard-workspace/objects-side-bar/pathology-type-list';
import ImageTypeList from 'components/annotation-page/standard-workspace/objects-side-bar/image-type-list';
import ImageQualityList from 'components/annotation-page/standard-workspace/objects-side-bar/image-quality-list';

import { adjustContextImagePosition } from 'components/annotation-page/standard-workspace/context-image/context-image';
import { collapseSidebar as collapseSidebarAction } from 'actions/annotation-actions';
import AppearanceBlock from 'components/annotation-page/appearance-block';
import IssuesListComponent from 'components/annotation-page/standard-workspace/objects-side-bar/issues-list';

interface OwnProps {
    objectsList: JSX.Element;
}

interface StateToProps {
    sidebarCollapsed: boolean;
    canvasInstance: Canvas | Canvas3d;
    jobInstance: any;
}

interface DispatchToProps {
    collapseSidebar(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            sidebarCollapsed,
            canvas: { instance: canvasInstance },
            job: { instance: jobInstance },
        },
    } = state;

    return {
        sidebarCollapsed,
        canvasInstance,
        jobInstance,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
    return {
        collapseSidebar(): void {
            dispatch(collapseSidebarAction());
        },
    };
}

function ObjectsSideBar(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        sidebarCollapsed, canvasInstance, collapseSidebar, objectsList, jobInstance,
    } = props;

    const [TEE, setTEE] = useState(true);

    const handleIsTee = (e: boolean): void => {
        setTEE(e);
    };

    // const [UBM, setUBM] = useState(true);

    // const handleIsUBM = (e: boolean): void => {
    //     setUBM(e);
    // };
    const imageTypeValue = useSelector((state: CombinedState) => state.annotation.annotations.imageType);

    const collapse = (): void => {
        const [collapser] = window.document.getElementsByClassName('cvat-objects-sidebar');
        const listener = (event: TransitionEvent): void => {
            if (event.target && event.propertyName === 'width' && event.target === collapser) {
                canvasInstance.fitCanvas();
                canvasInstance.fit();
                (collapser as HTMLElement).removeEventListener('transitionend', listener as any);
            }
        };

        if (collapser) {
            (collapser as HTMLElement).addEventListener('transitionend', listener as any);
        }

        adjustContextImagePosition(!sidebarCollapsed);
        collapseSidebar();
    };
    const is2D = jobInstance ? jobInstance.dimension === DimensionType.DIM_2D : true;
    return (
        <Layout.Sider
            className='cvat-objects-sidebar'
            theme='dark'
            width={300}
            collapsedWidth={0}
            reverseArrow
            collapsible
            trigger={null}
            collapsed={sidebarCollapsed}
        >
            {/* eslint-disable-next-line */}
            <span
                className={`cvat-objects-sidebar-sider
                    ant-layout-sider-zero-width-trigger
                    ant-layout-sider-zero-width-trigger-left`}
                onClick={collapse}
            >
                {sidebarCollapsed ? <MenuFoldOutlined title='Show' /> : <MenuUnfoldOutlined title='Hide' />}
            </span>

            <Tabs type='card' defaultActiveKey='objects' className='cvat-objects-sidebar-tabs'>
                <Tabs.TabPane tab={<Text strong>Objects</Text>} key='objects'>
                    {objectsList}
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={<Text strong>Labels</Text>} key='labels'>
                    <LabelsList
                        isTee={handleIsTee}
                    />
                </Tabs.TabPane>

                {is2D ? (
                    <Tabs.TabPane tab={<Text strong>Issues</Text>} key='issues'>
                        <IssuesListComponent />
                    </Tabs.TabPane>
                ) : null}
            </Tabs>

            {TEE && imageTypeValue === 'Biplane' && (
                <Tabs type='card' className='cvat-objects-sidebar-tabs-categories'>
                    <Tabs.TabPane tab={<Text strong>Right View Type</Text>} key='right-view-type'>
                        <RightViewTypeList />
                    </Tabs.TabPane>
                </Tabs>
            )}

            {TEE && (
                <Tabs type='card' className='cvat-objects-sidebar-tabs-categories'>
                    <Tabs.TabPane tab={<Text strong>Pathology type</Text>} key='pathology-type'>
                        <PathologyTypeList />
                    </Tabs.TabPane>
                </Tabs>
            )}
            {TEE && (
                <Tabs
                    type='card'
                    className='cvat-objects-sidebar-tabs-categories'
                >
                    <Tabs.TabPane tab={<Text strong>Image type</Text>} key='image-type'>
                        <ImageTypeList />
                    </Tabs.TabPane>
                </Tabs>
            )}

            {TEE && (
                <Tabs type='card' className='cvat-objects-sidebar-tabs-categories'>
                    <Tabs.TabPane tab={<Text strong>Image quality</Text>} key='image-quality'>
                        <ImageQualityList />
                    </Tabs.TabPane>
                </Tabs>
            )}

            {!sidebarCollapsed && <AppearanceBlock />}
        </Layout.Sider>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ObjectsSideBar));
