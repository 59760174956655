// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

// eslint-disable-next-line header/header
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import Select, { SelectProps } from 'antd/lib/select';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { useParams } from 'react-router';
import { labelFilterObject } from 'components/annotation-page/standard-workspace/objects-side-bar/labelset-data';
import { useSelectedContext } from 'components/annotation-page/job-preview/Provider';

interface Props extends SelectProps<string> {
    labels: any[];
    value: any | number | null;
    onChange: (label: any) => void;
}

export default function LabelSelector(props: Props): JSX.Element {
    const {
        labels, value, onChange, ...rest
    } = props;
    const context = useSelectedContext();
    const dinamicProps = value ?
        {
            value: typeof value === 'number' ? value : value.id,
        } :
        {};
    const { jid }:any = useParams();
    // const [labelSet, setLabelset] = useState('');

    const filterLabels = (): string[] => Object.entries(labelFilterObject).flatMap(([key, values]) => {
        if (key === context.view) {
            return values;
        }
        return [];
    });

    const selectedLabels = labels.filter((x) => filterLabels().includes(x.name));
    const filteredLabels = selectedLabels.length === 0 ? labels : selectedLabels;

    useEffect(() => {
        axios.get(`/api/jobs/${jid}/labelsets`).then((response) => context.setView(response.data.labelset));
    }, [filteredLabels]);

    return (
        <section>
            { filteredLabels.length > 0 ? (
                <Select
                    style={{ width: 100 }}
                    virtual={false}
                    {...rest}
                    {...dinamicProps}
                    // showSearch
                    filterOption={(input: string, option?: OptionData | OptionGroupData) => {
                        if (option) {
                            const { children } = option.props;
                            if (typeof children === 'string') {
                                return children.toLowerCase().includes(input.toLowerCase());
                            }
                        }

                        return false;
                    }}
                    key={filteredLabels[0].id}
                    defaultValue={filteredLabels[0].id}
                    onChange={(newValue: string) => {
                        const [label] = labels.filter((_label: any): boolean => _label.id === +newValue);
                        if (label) {
                            onChange(label);
                        } else {
                            throw new Error(`Label with id ${newValue} was not found within the list`);
                        }
                    }}
                >
                    {filteredLabels.map((label: any) => (
                        <Select.Option title={label.name} key={label.id} value={label.id}>
                            {label.name}
                        </Select.Option>
                    ))}
                </Select>
            ) : (
                <span>
                    Custom labels not loaded
                </span>
            )}
        </section>
    );
}
