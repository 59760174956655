import React, { useState, useEffect, useContext } from 'react';
import Modal from 'antd/lib/modal';
import Select from 'antd/lib/select';
import { connect } from 'react-redux';
import Notification from 'antd/lib/notification';
import { ImageTypeSet } from 'components/annotation-page/standard-workspace/objects-side-bar/image-type-data';
import { labelSets } from 'components/annotation-page/standard-workspace/objects-side-bar/labelset-data';
const { Option } = Select;
import { useSelectedContext, SelectedContext } from 'components/annotation-page/job-preview/Provider';
import { saveToLocalStorage, getFromLocalStorage } from 'components/task-page/localStorageUtils'

const FilterModal = ({ visible, onClose, onConfirm, taskInstance }) => {
  const [selectedImageType, setSelectedImageType] = useState('');
  const [selectedViewType, setSelectedViewType] = useState('');
  const [filterModalVisible, setFilterModalVisible] = useState(false);

  useEffect(() => {
    const imageTypeFromLocalStorage = getFromLocalStorage('selectedImageType');
    const viewTypeFromLocalStorage = getFromLocalStorage('selectedViewType');

    if (imageTypeFromLocalStorage) {
      setSelectedImageType(imageTypeFromLocalStorage);
    }

    if (viewTypeFromLocalStorage) {
      setSelectedViewType(viewTypeFromLocalStorage);
    }
  }, []);

  const handleImageTypeChange = (value) => {
    setSelectedImageType(value);
    saveToLocalStorage('selectedImageType', value);
  };

  const handleViewTypeChange = (value) => {
    setSelectedViewType(value);
    saveToLocalStorage('selectedViewType', value);
  };

  const handleFilterButtonClick = () => {
    onConfirm();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Filter jobs"
      footer={null}
      className="custom-modal"
    >
      <div className="filter-container">
        <label htmlFor="imageTypeSelect">Image type:</label>
        <Select
          id="imageTypeSelect"
          value={selectedImageType}
          onChange={handleImageTypeChange}
          className="filterSelect"
        >
          {ImageTypeSet.map((type) => (
            <Select.Option key={type} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className="filter-container">
        <label htmlFor="viewTypeSelect">View type:</label>
        <Select
          id="viewTypeSelect"
          value={selectedViewType}
          onChange={handleViewTypeChange}
          className="filterSelect"
        >
          {labelSets.map((type) => (
            <Select.Option key={type} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div>
        <button className="confirmButton" onClick={handleFilterButtonClick}>
          Filter
        </button>
      </div>
    </Modal>
  );
};

export default FilterModal;
