// eslint-disable-next-line header/header
export const labelSets = ['all', '5 CH', '5 CH RV ZOOM', '4 CH', '4 CH RV ZOOM', '2 CH + LAA ZOOM', '2 CH + LAA',
    '2 CH ZOOM', '2 CH', 'Long axis view', 'Av LAX view', 'Ascending Ao - LAX view',
    'Ascending Ao - SAX view', 'Rigth pulmonary vein view', 'AV SAX zoom view', 'AV SAX view',
    'RV inflow view', 'Bicaval AS Zoom view', 'Bicaval view', 'R / L pulmonary veins', 'LAA',
    'Basal MV ZOOM SAX', 'Basal SAX', 'Mid Papilary SAX', 'Apical SAX', 'RV basal view', 'RV inflow / outflow view',
    'Deep TG 5CH', '2 CHg', 'TG RV inflow view', 'TG LAX', 'Descending Ao SAX',
    'Descending Ao LAX', 'Ao arch LAX', 'Ao arch SAX', 'Contrast', 'Pacing Lead', 'Mass', 'Others'];


export const labelFilterObject = {
    '5 CH': ['AV', 'LA', 'RA', 'LV', 'RV', 'MV', 'TV', 'TMVR', 'TAVR', 'ED', 'ES'],
    '5 CH RV ZOOM': ['LA', 'RA', 'LV', 'RV', 'MV', 'TV', 'ED', 'ES'],
    '4 CH': ['LA', 'RA', 'LV', 'RV', 'MV', 'TV', 'TMVR', 'ED', 'ES'],
    '4 CH RV ZOOM': ['LA', 'RA', 'LV', 'RV', 'MV', 'TV', 'TMVR', 'ED', 'ES'],
    '2 CH + LAA ZOOM': ['MV', 'CS', 'LAA', 'TMVR', 'ED', 'ES'],
    '2 CH + LAA': ['LA', 'CS', 'CX', 'LV', 'MV', 'PM', 'Chordae', 'LAA', 'TMVR', 'ED', 'ES'],
    '2 CH ZOOM': ['MV', 'CS', 'LAA', 'TMVR', 'ED', 'ES'],
    '2 CH': ['LA', 'CS', 'CX', 'LV', 'MV', 'PM', 'Chordae', 'TMVR', 'ED', 'ES'],
    'Long axis view': ['LA', 'LV', 'MV', 'AV', 'Prox Asc Ao', 'TAVR', 'ED', 'ES'],
    'Av LAX view': ['LA', 'LV', 'MV', 'AV', 'Prox Asc Ao', 'TAVR', 'ED', 'ES'],
    'Ascending Ao - LAX view': ['Mid Asc Ao', 'PA', 'ED', 'ES'],
    'Ascending Ao - SAX view': ['Mid Asc Ao (SAX)', 'Main bifurcation', 'PA', 'SVC', 'ED', 'ES'],
    'Rigth pulmonary vein view': ['Mid Asc Ao (SAX)', 'SVC', 'RPV', 'ED', 'ES'],
    'AV SAX zoom view': ['AV', 'AS', 'PFO', 'TV', 'ED', 'ES'],
    'AV SAX view': ['AV', 'RA', 'LA', 'AS', 'RVOT', 'PV', 'LAA', 'TAVR', 'TV', 'RV', 'ED', 'ES'],
    'RV inflow view': ['AV', 'RA', 'LA', 'AS', 'TV', 'RV + RVOT', 'PV', 'TAVR', 'ED', 'ES'],
    'Bicaval AS Zoom view': ['AS', 'PFO', 'ED', 'ES'],
    'Bicaval view': ['LA', 'RA', 'LAA', 'RA RAA', 'AS', 'IVC', 'SVC', 'RAAC', 'ED', 'ES'],
    'R / L pulmonary veins': ['PV upper', 'PV lower', 'PA', 'ED', 'ES'],
    // eslint-disable-next-line quote-props
    'LAA': ['LAA', 'LPV', 'LAAC', 'ED', 'ES'],
    'Basal MV ZOOM SAX': ['MV', 'LVb', 'ED', 'ES'],
    'Basal SAX': ['LVb', 'RVb', 'MV', 'TV', 'ED', 'ES'],
    'Mid Papilary SAX': ['LVm', 'PM', 'RVm', 'ED', 'ES'],
    'Apical SAX': ['LV', 'RV apex', 'ED', 'ES'],
    'RV basal view': ['LV', 'RV', 'RVOT', 'TV', 'PV', 'ED', 'ES'],
    'RV inflow / outflow view': ['RA', 'RV', 'PV', 'TV', 'AV', 'LA', 'ED', 'ES'],
    'Deep TG 5CH': ['LV', 'LVOT', 'RV', 'AV', 'Ao root', 'MV', 'LA', 'ED', 'ES'],
    '2 CHg': ['LV', 'LA', 'LAA', 'MV', 'ED', 'ES'],
    'TG RV inflow view': ['RV', 'RA', 'TV', 'ED', 'ES'],
    'TG LAX': ['LV', 'LVOT', 'RV', 'AV', 'Ao', 'ED', 'ES'],
    'Descending Ao SAX': ['Desc Ao SAX', 'ED', 'ES'],
    'Descending Ao LAX': ['Desc Ao LAX', 'Left thorax', 'ED', 'ES'],
    'Ao arch LAX': ['Ao Arch', 'innominate vein', 'ED', 'ES'],
    'Ao arch SAX': ['Ao Arch', 'innominate vein', 'PA', 'PV', 'ED', 'ES'],
    // eslint-disable-next-line quote-props
    'Contrast': ['LAc', 'RAc', 'LVc', 'RVc', 'ED', 'ES'],
    'Pacing Lead': ['Pacing', 'LA', 'RA', 'LV', 'RV', 'MV', 'ED', 'ES'],
    'Mass': ['Thrombus/Vegetation', 'ED', 'ES'],
    // eslint-disable-next-line quote-props
    'all': ['AV', 'LVOT', 'LA', 'RA', 'LV', 'RV', 'MV', 'TV', 'TMVR', 'TAVR', 'CS', 'CX', 'PM', 'Chordae',
        'LAA', 'LPV', 'RVOT', 'RV + RVOT', 'Prox Asc Ao', 'Mid Asc Ao', 'PV', 'Mid Asc Ao (SAX)', 'RA RAA',
        'Main bifurcation', 'PA', 'SVC', 'PFO', 'Ao Arch', 'innominate vein', 'AO', 'RPV',
        'AS', 'IVC', 'PV upper', 'PV lower', 'LVb', 'RVb', 'LVm', 'RVm', 'LAAC', 'RAAC', 'LAc', 'RAc', 'LVc', 'RVc',
        'LV apex', 'RV apex', 'RVOT PV', 'Ao root', 'Ao', 'Desc Ao SAX',
        'Desc Ao LAX', 'Left thorax', 'Pacing', 'Thrombus/Vegetation', 'ED', 'ES'],
    'Others': ['AV', 'LVOT', 'LA', 'RA', 'LV', 'RV', 'MV', 'TV', 'TMVR', 'TAVR', 'CS', 'CX', 'PM', 'Chordae',
        'LAA', 'LPV', 'RVOT', 'RV + RVOT', 'Prox Asc Ao', 'Mid Asc Ao', 'PV', 'Mid Asc Ao (SAX)', 'RA RAA',
        'Main bifurcation', 'PA', 'SVC', 'PFO', 'Ao Arch', 'innominate vein', 'AO', 'RPV',
        'AS', 'IVC', 'PV upper', 'PV lower', 'LVb', 'RVb', 'LVm', 'RVm', 'LAAC', 'RAAC', 'LAc', 'RAc', 'LVc', 'RVc',
        'LV apex', 'RV apex', 'RVOT PV', 'Ao root', 'Ao', 'Desc Ao SAX',
        'Desc Ao LAX', 'Left thorax', 'Pacing', 'Thrombus/Vegetation', 'ED', 'ES'],
};
